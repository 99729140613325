import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import { Modal, ModalBody } from "reactstrap";
import { ButtonComponent } from "../Button";

export const WarningMessageModal = (props: any) => {
  const { contentFooter } = useSelector((state: RootState) => state.common);
  const content = contentFooter?.contentResource?.contents;
  const yesButtonLabel = ObjectUtils.extractLabelFromObject(content, "lblYes");
  const noButtonLabel = ObjectUtils.extractLabelFromObject(content, "lblNo");
  const lblWarningMessage = ObjectUtils.extractLabelFromObject(content, "lblWarningMessage");
  const lblWarningMessageTitle = ObjectUtils.extractLabelFromObject(content, "lblWarningMessageTitle");
  const closeButtonText = ObjectUtils.extractLabelFromObject(content || {}, "btnClose");

  /* const keyPressHandler = (e) => {
    console.log("wefjkbweb");

    if (e.which === 13 && props.clickAction) {
      e.preventDefault();
      props.clickAction(e);
    }
  }; */

  return (
    <Fragment>
      <Modal isOpen={props.showWarningModal} aria-labelledby="modalHeading" aria-describedby="modalDescription" className="ml-cancelenrollment" toggle={props.handleClose} centered>
        <div className="modal-header">
          <h1 className="modal-title" id="modalHeading">
            {lblWarningMessageTitle.display}
          </h1>

          <ButtonComponent
            btndata={[
              {
                variant: "close",
                ariaLabel: closeButtonText.display,
                display: "×",
                onSubmit: props.handleClose
              }
            ]}
          />
        </div>
        <ModalBody>
          <div className="ml-popupmessage" id="modalDescription">
            <p className="ml-popupmessagetext" dangerouslySetInnerHTML={{ __html: lblWarningMessage.display }}></p>
          </div>
          <div className={"ml-buttons d-flex justify-content-between"}>
            <ButtonComponent
              btndata={[
                {
                  variant: "outline-secondary btn-sm btn-cancel",
                  ariaLabel: noButtonLabel.display,
                  display: noButtonLabel.display,
                  onSubmit: props.handleClose
                }
              ]}
            />
            <ButtonComponent
              btndata={[
                {
                  variant: "outline-secondary btn-sm btn-submit",
                  ariaLabel: yesButtonLabel.display,
                  display: yesButtonLabel.display,
                  onSubmit: props.clickAction
                  // onKeyDown: keyPressHandler
                }
              ]}
            />
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default WarningMessageModal;
