import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

export const PagingComponent = () => {
  const { progressBar } = useSelector((state: RootState) => state.event);

  const renderPaging = () => {
    const paging: JSX.Element[] = [];
    for (let j = 0; j < progressBar.currentSubStep; j++) {
      paging.push(<span className="fill-circle active" key={`active-${j + 10}-ID`}></span>);
    }
    for (let j = 0; j < progressBar.numberofSubSteps - progressBar.currentSubStep; j++) {
      paging.push(<span className="fill-circle" key={`inactive-${j + 2}-ID`}></span>);
    }
    return paging;
  };

  return (
    <>
      {progressBar?.labelText && (
        <Row className={`justify-content-center mt-2 mb-3 mb-md-4`}>
          <span className={`paginationtext`}>({progressBar.labelText})</span>
          <div className={"pagination"}>{renderPaging()}</div>
        </Row>
      )}
    </>
  );
};

export default PagingComponent;
