import { useEffect, useState } from "react"; //Fragment,
import { Modal, ModalBody, ModalHeader } from "reactstrap"; //ModalBody
import { useSelector } from "react-redux";
import { getCustomPopUpURL } from "../../actions";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import { ButtonComponent } from "../Button";

export const WindowDialogComponent = () => {
  const contentFooter = useSelector((state: RootState) => state?.common?.contentFooter?.contentResource?.contents);
  const helpContentResponse = useSelector((state: RootState) => state.common.helpContentResponse || null);
  const [showPopUpContent, setshowPopUpContent] = useState<boolean>(false);
  const [modalTitle, setmodalTitle] = useState<string>("");
  const [dialogClass, setdialogClass] = useState<string>("");
  useEffect(() => {
    const handleURLClick = (event: any) => {
      const link = event?.target?.getAttribute("data-tid");
      const title = event?.target?.getAttribute("data-title") ?? "&nbsp;";
      const className = event?.target?.getAttribute("data-class");

      if (link) {
        getCustomPopUpURL(link, () => {
          setshowPopUpContent(true);
          setdialogClass(className);
          setmodalTitle(title);
        });
      }
    };
    const win: Window = window; // <-- DOM-Window, extends DOM-EventTarget
    win.addEventListener("click", handleURLClick);
    win.addEventListener("keypress", handleURLClick);
    return () => {
      window.removeEventListener("click", handleURLClick);
      window.removeEventListener("keypress", handleURLClick);
    };
  }, []);

  const handlePopUpClose = () => {
    setshowPopUpContent(false);
  };
  const closeButtonText = ObjectUtils.extractLabelFromObject(contentFooter || {}, "btnClose");

  return (
    <Modal isOpen={showPopUpContent} aria-labelledby="modalHeading" className={dialogClass || ""} centered toggle={handlePopUpClose}>
      <ModalHeader closeButton>
        <span dangerouslySetInnerHTML={{ __html: modalTitle }} />
        {handlePopUpClose && (
          <ButtonComponent
            btndata={[
              {
                variant: "close",
                ariaLabel: closeButtonText.display,
                display: "×",
                onSubmit: handlePopUpClose
              }
            ]}
          />
        )}
      </ModalHeader>
      <ModalBody>
        {showPopUpContent && (
          <div
            dangerouslySetInnerHTML={{
              __html: helpContentResponse?.result?.content
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default WindowDialogComponent;
