import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import { AlertComponent } from "../Alert";
import { ButtonComponent, NextPrevComponent } from "../Button";
import { GridComponent } from "../Card";

export const ModalComponent = ({ children, ...props }: any) => {
  const { showModal, dialogClass, head, body, footer, clickHandler, css, showHeaderCloseBtn = true } = props?.modalObject || {};
  const contentFooter = useSelector((state: RootState) => state?.common?.contentFooter?.contentResource?.contents);
  const closeButtonText = ObjectUtils.extractLabelFromObject(contentFooter || {}, "btnClose");
  return (
    <Modal aria-labelledby="modalHeading" className={dialogClass} isOpen={showModal} toggle={clickHandler} centered>
      <ModalHeader id="modalHeading" className={css?.modalHeader}>
        <span dangerouslySetInnerHTML={{ __html: head?.modalTitle }} className={css?.modalTitle} />
        {clickHandler && showHeaderCloseBtn && (
          <ButtonComponent
            btndata={[
              {
                variant: "close",
                ariaLabel: closeButtonText.display,
                display: "×",
                onSubmit: clickHandler
              }
            ]}
          />
        )}
      </ModalHeader>
      {showModal && (
        <ModalBody className={`${body?.confirmBoxData?.wrapper ?? ""}${body?.bodyClass ?? ""}`}>
          {body?.confirmBoxData && (
            <>
              {body?.confirmBoxData?.messagetitle && (
                <div className={`${body?.confirmBoxData?.messagetitleclass ?? ""}`} dangerouslySetInnerHTML={{ __html: body?.confirmBoxData?.messagetitle }} />
              )}
              {body?.confirmBoxData?.wrapper && <hr />}
              {body?.confirmBoxData?.messagetext && (
                <div className={`${body?.confirmBoxData?.messagepopclass ?? ""}`}>
                  <div className={`${body?.confirmBoxData?.messagetextclass ?? ""}`} dangerouslySetInnerHTML={{ __html: body?.confirmBoxData?.messagetext }} />
                </div>
              )}
            </>
          )}
          {!body?.viewbodyMode &&
            body?.gridRowData?.map((item: any, index: number) => {
              return <GridComponent key={index} bodyData={item} rowdata={item[0]} />;
            })}

          {body?.messageText && <AlertComponent marginleft={false} propmessageType={body?.messageType} propmessage={body?.messageText} />}
          {children && <>{children}</>}

          {/* <div className="alert alert-info mt-4 mb-0" >
            <p className="m-0" role="alert">{fileUploadPageFooterMessage.display}</p>
          </div> */}
          {body?.showNextPrev && (
            <div className="ml-btnslifechangeevent">
              <NextPrevComponent
                lastPage={false}
                firstPage={true}
                nextClick={body?.showNextPrev?.agreeDisclaimerPresses || ""}
                cancelClicked={body?.showNextPrev?.cancelClicked || ""}
                previousClick={body?.showNextPrev?.cancelClicked || ""}
                lblNext={body?.showNextPrev?.iAgree || ""}
                lblCancel={body?.showNextPrev?.idontAgree || ""}
                lblPrevious={body?.showNextPrev?.idontAgree || ""}
                showButtons={body?.showNextPrev?.showButtons || false}
                onClick={body?.showNextPrev?.onClick || ""}
              />
            </div>
          )}
        </ModalBody>
      )}
      {footer?.footerData && (
        <ModalFooter className={`ml-popupbuttons justify-content-between ${css?.modalFooter ?? ""}`}>
          <ButtonComponent btndata={footer?.footerData} />
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ModalComponent;
